import TermSelect from "./filter_fields/Term";
import Location from "./filter_fields/Location";
import CourseNumber from "./filter_fields/CourseNumber";
import Title from "./filter_fields/Title";
import StartDate from "./filter_fields/StartDate";
import EndDate from "./filter_fields/EndDate";
import StartTime from "./filter_fields/StartTime";
import EndTime from "./filter_fields/EndTime";
import DaysofWeek from "./filter_fields/DaysCheckBox";
import Instructor from "./filter_fields/Instructor";
import OnlineOnly from "./filter_fields/OnlineOnly";
import OpenClasses from "./filter_fields/OpenClasses";
import Subject from "./filter_fields/Subject";
import "./Filters.css";

function Filters(props) {

  return (
      <>
          <hr />
          <div className="l-section l-section--four-col">
            <div className="l-section__inner">
              <div className="l-section__grid">
                <div className="l-section__col">
                  <TermSelect coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <Title coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <CourseNumber coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <Location coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj }/>
                </div>
                <div className="l-section__col">
                  <Instructor coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} instructors={props.instructors} instLoading={props.instLoading} />
                  <OnlineOnly coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <OpenClasses  coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                </div>
                <div className="l-section__col">
                  <Subject coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} subjects={props.subjects} subLoading={props.subLoading} />
                </div>
                <div className="l-section__col">
                  <StartTime coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <EndTime coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <StartDate coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <EndDate coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <DaysofWeek coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <a href='#hr_scroll' id="submitBtn">View Results</a>
          <a href="/" id="resetBtn">Reset</a>
          <br></br>
          <hr id='hr_scroll'/>
      </>
  );
}

export default Filters;
