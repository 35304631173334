import {useEffect, useState} from "react";
import CourseDetails from "./course_card/CourseDetails";
import './Courses.css';
import UserSelectedFilters from "../user_selected_filters/UserSelectedFilters";
import NoResults from "./no_results/NoResults";
import Loader from "../loader/Loader";
import {domain} from "../../constants/Global";
import SpecailChar from './../../constants/special-char-converstion.json';


function Courses(props) {
  let [loading, setLoading] = useState(false);
  let [noData, setNoData] = useState(false);
  const [courses, setCourses] = useState([]);
  const AllCourses = {};

  function loadCourses(URL) {
    setLoading(true);
    fetch(
        URL,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if(data.Report_Entry.length === 0) {
          setNoData(true);
          setLoading(false);
          return;
        }
        setCourses(data.Report_Entry);
        setLoading(false);
        setNoData(false);
      })
      .catch((error) => {
        setLoading(false);
        setNoData(true);
        console.log(error);
      });
  }

  //Sets a key for each object array
  function setArray(Array, GlobalArray, Needle) {
    Array.map((item) => (
      GlobalArray[item[Needle]] = item
    ));
  }

  //Orders Array asc by Key
  function orderArray(Array) {
    const sorted = Object.keys(Array)
      .sort()
      .reduce((accumulator, key) => {
        accumulator[key] = Array[key];
        return accumulator;
      }, {});
    return sorted;
  }

  //Sorts Meeting Times Array Chronologically
  function meetingFunction(times){
    let sortedTimes = [];
    let newTimes = [];
    for ( let i = 0; i < times.length; i++) {
      let newTimeObj = { key: '', value: '', };
      let timeKey = parseInt(times[i].match(/([0-9]{2}\/[0-9]{2})/)[0].toString().replace('/', ''));
      let timeValue = times[i];
      newTimeObj.key = timeKey;
      newTimeObj.value = timeValue;
      sortedTimes.push(newTimeObj);
    }
    sortedTimes.sort((a, b) => a.key - b.key);
    for( let i = 0; i < sortedTimes.length; i++) {
      newTimes.push(sortedTimes[i].value);
    }
    return newTimes;
  }

  //Loops through REST data (Courses) and formats the courses for HTML output.
  function sanatizeArray(groupArray, Array) {
    //Set defaults for Output
    let dateArray = ['Last_Day_to_Add', 'Last_Day_to_Drop', 'Last_Day_to_Withdraw' ];
    let defaultValues = {
      'Primary_Instructor_Email': '',
      'Crosslist_Seating': '',
      'Course_Prerequisites': 'None',
      'co_requisite_courses': 'None',
      'Special_Topic': 'None',
      'cluster_course_sections': 'None',
    };
    let keys = Object.keys(Array);

    keys.map((value) => {

      //Check if key is already in Subject or Course array
      //This decides how the course will be displayed
      if (!groupArray["subject"].hasOwnProperty(Array[value].Course_Subjects)){
        groupArray["subject"][Array[value].Course_Subjects] = Array[value].Course_Subjects;
        Array[value]["display_subject"] = true;
      }
      else {
        Array[value]["display_subject"] = false;
      }
      //handle course_number
      if (!groupArray["course_number"].hasOwnProperty(Array[value].Course_Number)) {
        groupArray["course_number"][Array[value].Course_Number] = Array[value].Course_Number;
        Array[value]["display_description"] = true;
      }
      else if (groupArray["course_number"].hasOwnProperty(Array[value].Course_Number)) {
        Array[value]["display_description"] = false;
      }

      //FORMAT - Start/End Date
      // if (Array[value].hasOwnProperty("StartDate") && Array[value].hasOwnProperty("EndDate")) {
      //  let start_date = formatDate(Array[value].StartDate, "short");
      //  let end_date = formatDate(Array[value].EndDate, "short" );
      //  Array[value]["start_to_end"] = "" + start_date + " - " + end_date;
      //}

      //FORMAT - Meeting Time
      if (Array[value].hasOwnProperty("Meeting_Times")){
        let time = Array[value].Meeting_Times;
        let times = Array[value].Meeting_Times.split(';');
        let sortedTimes = [];

        if (times[0].match(/([0-9]{2}\/[0-9]{2})/)) {
          sortedTimes = meetingFunction(times);
        }

        if(times.length === 1){
          Array[value]["meeting_time"] = time.substring(time.indexOf("| ")+ 2, time.length);
        }
        else{
          Array[value]["meeting_time"] = "Differential Meeting Times";
          Array[value]["multiple_meeting_time"] = sortedTimes.length ? sortedTimes : times;
        }

      }

      //FORMAT - Important Dates (dateArray)
      dateArray.map((dateField) => {
        if (Array[value].hasOwnProperty(dateField)){
          let date = formatDate(Array[value][dateField]);
          Array[value][dateField.toLowerCase()] = date;
          return date;
        } else {
          return null;
        }
      });

      //FORMAT - Description
      //Public_Notes
      if(Array[value].hasOwnProperty("Description")){
        Array[value]['description'] = cleanHTML(Array[value].Description, Array[value]['description']);
      }

      //FORMAT - Public Notes
      if(Array[value].hasOwnProperty("Public_Notes")){
        Array[value]['public_notes'] = cleanHTML(Array[value].Public_Notes, Array[value]['public_notes']);
      }

      //FORMAT - Crosslist Seating
      if(Array[value].hasOwnProperty("Crosslist_Seating")){
        let crosslist_array = Array[value].Crosslist_Seating.split(';');
        Array[value]['crosslist_seating'] = crosslist_array;
        if(Array[value].Crosslist_Seating !== ''){
          Array[value]['display_crosslist_seating'] = true;
        }
      }

      //FORMAT - Cluster_Course_Section
      if(Array[value].hasOwnProperty("Cluster_Course_Sections")){
        Array[value]["cluster_course_sections"] = formatCoReqs(Array[value], "Cluster_Course_Sections", ",");
      }

      //FORMAT - Co_Requisite_Courses
      if(Array[value].hasOwnProperty("Co_Requisite_Courses")){
        Array[value]["co_requisite_courses"] = formatCoReqs(Array[value], "Co_Requisite_Courses", "; ");
      }

      //FORMAT - Allowed_Locations (Campus)
      if(Array[value].hasOwnProperty("Campus")){
        Array[value]["campus"] = Array[value]['Campus'].replace('_', ' ');
      }

      if(!Array[value].hasOwnProperty("Room")){
        Array[value]["building_location"] = Array[value].Delivery_Mode;
        Array[value]["room_location"] = '';
      }

      if(Array[value].hasOwnProperty("Room")){
        Array[value]["room_location"] = Array[value].Room;
      }


      //SET DEFAULTS - defaultValues array
      for(let checkKey in defaultValues){
        if(!Array[value].hasOwnProperty(checkKey)){
          Array[value][checkKey] = defaultValues[checkKey];
        }
      }
      return Array;
    });
  }

  function cleanHTML(HTMLString, updateValue){
    let removeHTML = '';
    let first = true;
    SpecailChar.map((char) => {
      if(HTMLString.includes(char.Numeric)){
        if(first){
          removeHTML = HTMLString.replaceAll(char.Numeric , char.Chr);
          first = false;
        }
        else{
          removeHTML = removeHTML.replaceAll(char.Numeric , char.Chr);
        }
      }
      return removeHTML;
    });
    if(removeHTML !== ''){
      removeHTML = removeHTML.replace( /(<([^>]+)>)/ig, '');
      updateValue = removeHTML;
    }
    else{
      removeHTML = HTMLString.replace( /(<([^>]+)>)/ig, '');
      updateValue = removeHTML;
    }
    return updateValue;
  }

  function formatDate(date, format = "long") {
    let dateArray = date.substring(0,10).split('-');
    if(format === "long"){
      return dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0];
    }
    else if(format === "short"){
      return dateArray[1] + "/" + dateArray[2];
    }
  }

  function formatCoReqs(array, arrayKey, splitValue){
      let requisite_array =  array[arrayKey].split(splitValue);
      if(requisite_array[0] !== "None"){
        let co_req_text = 'Students must sign up for ';
        for(let courseName of requisite_array){
          let isLast = requisite_array.indexOf(courseName) + 1 === requisite_array.length ? true: false;
          if(isLast === false){
            co_req_text = co_req_text + courseName + ', ';
          }
          if(isLast && requisite_array.length > 1){
            co_req_text = co_req_text + 'and ' + courseName;
          }
          if(isLast && requisite_array.length === 1){
            co_req_text = co_req_text + courseName;
          }
        }
        co_req_text = co_req_text + ' concurrently.';
        return co_req_text;
      }
  }

  function buildURL(){
    let baseURL = domain + '/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Courses_-_Copy_CSW%3Fformat%3Djson';
    let baseWorkdayURL = 'https://services1.myworkday.com/ccx/service/customreport2/aims/ISU_INT172_PSC/INT172_Aims_Public_Schedule_Courses_-_Copy_CSW?format=json';
    let amp = '%26';
    let equal = '%3D';
    let data = props.coursesData;
    for(let [key, value] of Object.entries(data)){
      if(value !== '' && key !== 'Instructor' && key !== 'Subject' && key !== 'default'){
        baseURL = baseURL + amp + key + equal + value;
        baseWorkdayURL = baseWorkdayURL + '&' + key + '=' + value;
      }
      if(key !== '' && key === 'Instructor' && value !== ''){
        let employeeID = value.split('-');
        baseURL = baseURL + amp + key + equal + employeeID[0];
        baseWorkdayURL = baseWorkdayURL + '&' + key + '=' + employeeID[0];
      }
      if(key !== '' && key === 'Subject' && value !== ''){
        let subjectID = value.split('-');
        baseURL = baseURL + amp + key + equal + subjectID[0];
        baseWorkdayURL = baseWorkdayURL + '&' + key + '=' + subjectID[0];
      }
      if(key === 'default'){
        continue;
      }
    }
    let url = baseURL.replaceAll('%26', '&');
    url.replaceAll('%3D', '=');
    url.replaceAll('%3F', '?');
    // console.log('Human Readable URL generated: ' + url);
    // console.log('Workday equivalent: ' + baseWorkdayURL);
    return baseURL;
  }

  function hashURL (object) {
    let queryArgs = '';
    for(let [key, value] of Object.entries(object)){
      if(value !== '' && key !== 'Instructor' && key !== 'Subject'){
        queryArgs = queryArgs + '&' + key + '=' + value;
      }
      if(key === 'Instructor' && value !== ''){
        let employeeID = value.split('-');
        queryArgs = queryArgs + '&' + key + '=' + employeeID[0];
      }
      if(key === 'Subject' && value !== ''){
        let subjectID = value.split('-');
        queryArgs = queryArgs + '&' + key + '=' + subjectID[0];
      }
    }
    let newArgs = queryArgs.replace(/&/, 'search?');
    window.history.replaceState(null, null, newArgs);
  }

  setArray(courses, AllCourses, "SISID");
  const sorted = orderArray(AllCourses);
  const keys = Object.keys(sorted);
  const groupArray = { subject: [], course_number: [] };
  let urlData = props.coursesData;
  sanatizeArray(groupArray, sorted);

  // eslint-disable-next-line
  useEffect(() => {
    loadCourses(buildURL());
    hashURL(urlData);
  }, [urlData]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (noData ? <><UserSelectedFilters coursesData={props.coursesData} instructors={props.instructors} subjects={props.subjects}/><NoResults /></> :
          <>
            <UserSelectedFilters coursesData={props.coursesData} instructors={props.instructors} subjects={props.subjects}/>
            {keys.map((value) => {
              if (
                  sorted[value].display_subject &&
                  sorted[value].display_description
              ) {
                return [
                  <>
                    <h3>{sorted[value].Course_Subjects}</h3>
                    <div>
                      <h4 className="course-title">
                        {sorted[value].Course_Title}
                      </h4>
                      <p>{sorted[value].description}</p>
                      <CourseDetails sorted={sorted} value={value} />
                    </div>
                  </>,
                ];
              } else if (
                  sorted[value].display_description &&
                  sorted[value].display_subject === false
              ) {
                return [
                  <div>
                    <h4 className="course-title">
                      {sorted[value].Course_Title}
                    </h4>
                    <p>{sorted[value].description}</p>
                    <CourseDetails sorted={sorted} value={value} />
                  </div>,
                ];
              } else if (
                  sorted[value].display_description === false &&
                  sorted[value].display_subject === false
              ) {
                return [<CourseDetails sorted={sorted} value={value} />];
              } else {
                return [null]
              }
            })}
          </>
      )}
    </>
  );
}

export default Courses;
