import "./Footer.css";

function Footer() {
    return(
        <>
            <div className="sub-footer l-section--normal t-normal t-gutter-normal t- l-section">
                <div className="footer-l-constrained l-section__inner">
                    <div className="l-section__col l-section__col--">
                        <div id="block-footerbottomaimsaddress">


                            <div
                                className="text-content field field--name-body field--type-text-with-summary field--label-hidden field__item">
                                <p><span className="white">Aims Community College</span><span className="dot">•</span><a
                                    href="tel:9703309009">(970) 330-9009</a><span className="dot">•</span> 5401 West
                                    20th
                                    Street, Greeley, CO 80634</p>
                            </div>

                        </div>
                        <div id="block-footerbottomcopyright">


                            <span>© 2024</span><p>&nbsp;Aims Community College</p>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default Footer;