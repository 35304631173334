import "./Header.css";

function Header(){
    return(
        <>
            <header className="header" role="banner">
                <div className="header l-section--normal t-normal t-gutter-normal t- l-section">
                    <div className="header l-section__inner">
                        <div className="header l-section__col l-section__col--">
                            <div className="c-site-header__logo">
                                <a href="/" title="Home" rel="home" className="logo">
                                    <img src="https://safety.aims.edu/themes/custom/aims_emergency/logo.svg"
                                         alt="Emergency Alerts logo"
                                         className="logo__image"/>
                                </a></div>
                            <h1 className="c-site-header__name">
                                Class Schedule
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="primary-nav">
                    <div className="l-section--normal t-normal t-gutter-normal t- l-section">
                        <div className="l-section__inner">
                            <div className="l-section__col">
                                <div className="c-site-header__site-navigation" id="mainMenuControl">
                                    <div className="l-constrained">
                                        <div className="c-site-header__menu-main">
                                            <div>
                                                <nav role="navigation" aria-label="block-aims-emergency-main-menu-menu"
                                                     id="block-aims-emergency-main-menu">


                                                    <ul className="menu menu--main" data-depth="0">
                                                        <li className="menu__item">
                                                            <a href="/" className="menu__link is-active"
                                                               data-drupal-link-system-path="<front>">Home</a>
                                                        </li>
                                                        <li className="menu__item">
                                                            <a href="https://www.aims.edu/degrees-certificates"
                                                               title="All Aims Degrees and Certificates"
                                                               className="menu__link"
                                                               target="_blank" rel="noreferrer"
                                                               data-drupal-link-system-path="node/59">Degrees &
                                                                Certificates</a>
                                                        </li>
                                                        <li>
                                                            <a href="https://www.aims.edu/admissions/apply-now"
                                                               title="Apply at Aims now" target="_blank" rel="noreferrer">Apply Now</a>
                                                        </li>
                                                    </ul>


                                                </nav>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;