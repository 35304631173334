import { useState } from "react";

function DaysofWeek(props) {

  const [linkedMonday] = useState(props.coursesObj.IsMonday);
  const [linkedTuesday] = useState(props.coursesObj.IsTuesday);
  const [linkedWednesday] = useState(props.coursesObj.IsWednesday);
  const [linkedThursday] = useState(props.coursesObj.IsThursday);
  const [linkedFriday] = useState(props.coursesObj.IsFriday);
  const [linkedSaturday] = useState(props.coursesObj.IsSaturday);
  const [linkedSunday] = useState(props.coursesObj.IsSunday);

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      [e.target.name]: e.target.checked,
      default: false,
    });
  }

  return (
      <>
        <label className="majorLabel">Days of the Week:</label>
        {linkedMonday === 'true'
          ?
          <input type="checkbox" id="monday" name="IsMonday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="monday" name="IsMonday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label for="monday">Monday</label>
        <br />
        {linkedTuesday === 'true'
          ?
          <input type="checkbox" id="tuesday" name="IsTuesday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="tuesday" name="IsTuesday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label for="tuesday">Tuesday</label>
        <br />
        {linkedWednesday === 'true'
          ?
          <input type="checkbox" id="wednesday" name="IsWednesday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="wednesday" name="IsWednesday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label for="wednesday">Wednesday</label>
        <br />
        {linkedThursday === 'true'
          ?
          <input type="checkbox" id="thursday" name="IsThursday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="thursday" name="IsThursday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label for="thursday">Thursday</label>
        <br />
        {linkedFriday === 'true'
          ?
          <input type="checkbox" id="friday" name="IsFriday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="friday" name="IsFriday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label htmlFor="friday">Friday</label>
        <br />
        {linkedSaturday === 'true'
          ?
          <input type="checkbox" id="saturday" name="IsSaturday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="saturday" name="IsSaturday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label htmlFor="saturday">Saturday</label>
        <br />
        {linkedSunday === 'true'
          ?
          <input type="checkbox" id="sunday" name="IsSunday" value="true" className="daysCheckbox" defaultChecked onChange={handleStateChange}></input>
          :
          <input type="checkbox" id="sunday" name="IsSunday" value="true" className="daysCheckbox" onChange={handleStateChange}></input>
        }
        <label for="sunday">Sunday</label>
      </>
  );
}

export default DaysofWeek;
