import './UserSelectedFilters.css';
import {domain, filterNameKey, filterValueKey} from "../../constants/Global";
import {useEffect, useState} from "react";

function UserSelectedFilters(props){

    const [allInstructors, setAllInstructors] = useState([]);
    const [allSubjects, setAllSubjects] = useState([]);



    function santizeInstArray(instructors){
        let allInstructors = [];
        for(let instructor of instructors){
            let nameAndID = instructor["Employee_ID-Name"];
            let employeeInfoArray = nameAndID.split('-');
            let employeeID = employeeInfoArray[0];
            let employeeName = employeeInfoArray[1];
            let employeeFullName = employeeName.split(' ');
            let employeeLastName = employeeFullName[employeeFullName.length - 1];
            employeeFullName.pop();
            let employeeFirstAndMiddle = employeeFullName.join(' ');
            //Writing case for specific professor
            if(employeeID === 'a57f720cac93014d0dbefb439a02d3d7'){
                let rewriteName = employeeName.split(' ');
                employeeFirstAndMiddle = rewriteName[0];
                employeeLastName = rewriteName[1] + ' ' + rewriteName[2];
            }
            let employee = {
                'employeeName': employeeName,
                'employeeID': employeeID,
                'employeeFirstAndMiddle': employeeFirstAndMiddle,
                'employeeLastName': employeeLastName
            };
            allInstructors.push(employee);
        }
        return allInstructors.sort((a, b) => a.employeeLastName.localeCompare(b.employeeLastName));
    }

    function santizeSubArray(subjects){
        let allSubjects = [];
        for(let subject of subjects){
            let nameAndID = subject["SISID-SubjectDescription"];
            let subjectInfoArray = nameAndID.split('-');
            let subjectID = subjectInfoArray[0];
            let subjectName = subjectInfoArray[1];
            let subjectObject = {'subjectName': subjectName, 'subjectID': subjectID};
            allSubjects.push(subjectObject);
        }
        return allSubjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    }

    function sanitizeTime (time) {
        if(parseInt(time.split(':')[0]) > 12){
            let hour = parseInt(time) - 12;
            let minute = time.split(':')[1];
            return time = hour + ':' + minute + ' PM';
        }
        else{
            if(time.charAt(0) === '0'){
                return time = time.substring(1) + ' AM';
            }
            else{
                return time = time + ' AM';
            }
        }
    }

    function fetchInstructors () {
        if(props.instructors.length === 0){
            fetch(
                domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Instructors%3Fformat%3Djson",
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setAllInstructors(santizeInstArray(data.Report_Entry))
                })
                .catch((error) => console.log(error));
        }
        else{
            setAllInstructors(santizeInstArray(props.instructors));
        }
    }

    function fetchSubjects () {
        if(props.subjects.length === 0){
            fetch(
                domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Subjects%3Fformat%3Djson",
                {
                    method: "GET",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setAllSubjects(santizeSubArray(data.Report_Entry))
                })
                .catch((error) => console.log(error));
        } else {
            setAllSubjects(santizeSubArray(props.subjects));
        }
    }

    useEffect(() => {
        fetchInstructors();
        fetchSubjects();
    }, []);

    return(
        <>
            <h2>Search Results: </h2>
            <div className="c-results-filter__container">
                {Object.entries(props.coursesData).map(([key, value]) => {
                    if (value && key !== 'default') {
                        let name = (filterNameKey.hasOwnProperty(key)) ? filterNameKey[key] : key;
                        let filter_value = value;
                        if (filterValueKey.hasOwnProperty(key)) {
                            if (filterValueKey[key].hasOwnProperty(value)) {
                                filter_value = filterValueKey[key][value];
                            }
                        }
                        if (name === 'Instructor' || name === 'Subject') {
                            let temp_val = filter_value.split('-');
                            if (temp_val.length === 1) {
                                if(name === 'Instructor'){
                                    if(allInstructors.length !== 0){
                                        let foundInst = allInstructors.find(obj => obj.employeeID === temp_val[0]);
                                        filter_value = foundInst.employeeName;
                                    }
                                }
                                else if (name === 'Subject') {
                                    if(allSubjects.length !== 0) {
                                        let foundSubject = allSubjects.find(obj => obj.subjectID === temp_val[0]);
                                        filter_value = foundSubject.subjectName;
                                    }
                                }
                            } else {
                                filter_value = temp_val[1];
                            }
                        }
                        if (name === 'Class Starts After' || name === 'Class Ends By') {
                            filter_value = sanitizeTime(filter_value);
                        }
                        return [
                            <>
                                <div className="c-results-filter__object-container">
                                    <p className="c-results-filter-display__filter-title">{name}</p>
                                    <ul className="c-results-filter-display__filters">
                                        <li className="u-tag">{filter_value}</li>
                                    </ul>
                                </div>
                            </>
                        ];
                    } else {
                        return null;
                    }
                })}
            </div>
        </>
    );
}

export default UserSelectedFilters;