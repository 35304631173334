
function OpenClasses(props) {

  const name = "SectionStatus";

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      SectionStatus: e.target.value,
      default: false,
    });
  }

  return (
    <>
      {props.coursesObj.SectionStatus !== '' && props.coursesObj.SectionStatus === 'Open'
        ?
        <>
          <label htmlFor="Online_Only" className="majorLabel">Open Classes Only:</label>
          <input type="radio" id="yesOpen" name={name} value="Open" className="radio" checked onChange={handleStateChange}/>
          <label for="yesOpen">Yes</label>
          <input type="radio" id="noOpen" name={name} value="Closed" className="radio noOption" onChange={handleStateChange}/>
          <label for="noOpen">No</label>
        </>
        :
        null
      }
      {props.coursesObj.SectionStatus !== '' && props.coursesObj.SectionStatus === 'Closed'
        ?
        <>
          <label htmlFor="Online_Only" className="majorLabel">Open Classes Only:</label>
          <input type="radio" id="yesOpen" name={name} value="Open" className="radio" onChange={handleStateChange}/>
          <label for="yesOpen">Yes</label>
          <input type="radio" id="noOpen" name={name} value="Closed" className="radio noOption" checked onChange={handleStateChange}/>
          <label for="noOpen">No</label>
        </>
        :
        null
      }
      {props.coursesObj.SectionStatus === ''
        ?
        <>
          <label htmlFor="Online_Only" className="majorLabel">Open Classes Only:</label>
          <input type="radio" id="yesOpen" name={name} value="Open" className="radio" onChange={handleStateChange}/>
          <label for="yesOpen">Yes</label>
          <input type="radio" id="noOpen" name={name} value="Closed" className="radio noOption" onChange={handleStateChange}/>
          <label for="noOpen">No</label>
        </>
        :
        null
      }
    </>
  );
}

export default OpenClasses;
