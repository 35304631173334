
function Subject(props) {

    const name = "Subject";

    function santizeArray(subjects){
      let allSubjects = [];
      for(let subject of subjects){
        let nameAndID = subject["SISID-SubjectDescription"];
        let subjectInfoArray = nameAndID.split('-');
        let subjectID = subjectInfoArray[0];
        let subjectName = subjectInfoArray[1];
        let subjectObject = {'subjectName': subjectName, 'subjectID': subjectID};
        allSubjects.push(subjectObject);
      }
      return allSubjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    }

    function handleStateChange (e) {
      let coursesObj = props.coursesObj;
        props.setCoursesObj({
            ...coursesObj,
            Subject: e.target.value,
            default: false,
        });
    }

    let allSubjects = santizeArray(props.subjects);


    return (
        <>
            <label htmlFor="subjects" className="majorLabel">Subject:</label>
            <select name={name} id="subjects" size="5" className="selectBox" onChange={handleStateChange}>
                {props.subLoading ? (
                    <option>Loading List...</option>
                ) : (
                    allSubjects.map((subject) => {
                      if(subject.subjectID !== props.coursesObj.Subject){
                        return [
                          <>
                            <option id={subject.subjectID} value={subject.subjectID + '-' + subject.subjectName}>{subject.subjectID} - {subject.subjectName}</option>
                          </>
                        ]
                      }
                      else{
                        return [
                          <>
                            <option id={subject.subjectID} value={subject.subjectID + '-' + subject.subjectName} selected>{subject.subjectID} - {subject.subjectName}</option>
                          </>
                        ]
                      }
                    })
                )}
            </select>
        </>
    )
}


export default Subject;
