
function Instructors(props) {

  const name = "Instructor";

  function santizeArray(instructors){
    let allInstructors = [];
    for(let instructor of instructors){
      let nameAndID = instructor["Employee_ID-Name"];
      let employeeInfoArray = nameAndID.split('-');
      let employeeID = employeeInfoArray[0];
      let employeeName = employeeInfoArray[1];
      let employeeFullName = employeeName.split(' ');
      let employeeLastName = employeeFullName[employeeFullName.length - 1];
      employeeFullName.pop();
      let employeeFirstAndMiddle = employeeFullName.join(' ');
      //Writing case for specific professor
      if(employeeID === 'a57f720cac93014d0dbefb439a02d3d7'){
        let rewriteName = employeeName.split(' ');
        employeeFirstAndMiddle = rewriteName[0];
        employeeLastName = rewriteName[1] + ' ' + rewriteName[2];
      }
      let employee = {
        'employeeName': employeeName,
        'employeeID': employeeID,
        'employeeFirstAndMiddle': employeeFirstAndMiddle,
        'employeeLastName': employeeLastName
      };
      allInstructors.push(employee);
    }
    return allInstructors.sort((a, b) => a.employeeLastName.localeCompare(b.employeeLastName));
  }

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
      props.setCoursesObj({
        ...coursesObj,
        Instructor: e.target.value,
        default: false,
      });
  }

  let allInstructors = santizeArray(props.instructors);


  return (
      <>
        <label htmlFor="instructors" className="majorLabel">Instructor:</label>
        <select name={name} id="instructors" size="5" className="selectBox" onChange={handleStateChange}>
          {props.instLoading ? (
                <option>Loading List...</option>
              ) : (
                  allInstructors.map((instructor) => {
                    if(instructor.employeeID !== ''){
                      if(instructor.employeeID !== props.coursesObj.Instructor){
                        return [
                          <>
                            <option id={instructor.employeeID} value={instructor.employeeID + '-' + instructor.employeeName}>{instructor.employeeLastName}, {instructor.employeeFirstAndMiddle}</option>
                          </>
                        ]
                      }
                      else{
                        return [
                          <>
                            <option id={instructor.employeeID} value={instructor.employeeID + '-' + instructor.employeeName} selected='true'>{instructor.employeeLastName}, {instructor.employeeFirstAndMiddle}</option>
                          </>
                        ]
                      }
                    } else {
                      return null;
                    }
                })
          )}
        </select>
      </>
  )
}



export default Instructors;
