import { useState } from "react";

function StartTime(props) {

  const [startTime, setStartTime] = useState(props.coursesObj.StartAfter);
  const name = "StartAfter";

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      StartAfter: e.target.value,
      default: false,
    });
    setStartTime(e.target.value);
  }

  return (
      <>
        <label htmlFor="startTime" className="majorLabel">Time the Class Starts After:</label>
        <input
            type="time"
            id="startTime"
            name={name}
            value={startTime}
            onChange={handleStateChange}
            className="selectBox dayTimeBox"
        />
      </>
  );
}

export default StartTime;
