import { useState } from "react";

function Title(props) {

  const name = "Title";
  const [title, setTitle] = useState('');

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    setTitle(e.target.value);
    setTimeout(() => {
      props.setCoursesObj({
        ...coursesObj,
        Title: e.target.value,
        default: false,
      });
    }, 2000);
  }

  return (
    <>
      <label htmlFor="Title" className="majorLabel">Title:</label>
      <input
          type="input"
          id="Title"
          name={name}
          value={title}
          onChange={handleStateChange}
          className="inputBox"
          placeholder={props.coursesObj.Title ? props.coursesObj.Title : "Course Title"}
      />
    </>
  );
}

export default Title;
