import { useState } from "react";

function CourseNumber(props) {

  const [course, setCourse] = useState(props.coursesObj.CourseNumber);
  const name = "CourseNumber";

  function handleStateChange (e) {
    setCourse(e.target.value);
    let coursesObj = props.coursesObj;
    setTimeout(()=> {
      props.setCoursesObj({
        ...coursesObj,
        CourseNumber: e.target.value,
        default: false,
      });
    }, 2000);
  }

  return (
      <>
          <label htmlFor="courseNum" className="majorLabel">Course #:</label><span className="help-text">No spaces allowed</span>
          <input
              type="input"
              id="courseNum"
              name={name}
              value={course}
              onChange={handleStateChange}
              className="inputBox"
              // placeholder={linkedCourse}
          ></input>
      </>
  );
}

export default CourseNumber;
